import React, { LegacyRef } from 'react';

import classNames from 'classnames';

import styles from './CaptchaIframe.css';
import useCaptcha from './hooks/useCaptcha';

type TCaptchaIframeProps = {
  iframeRef: LegacyRef<HTMLIFrameElement>;
  iframeURL: string;
  setIsIframeCaptchaLoaded: (value: boolean) => void
};

const CaptchaIframe = ({
  iframeRef,
  iframeURL,
  setIsIframeCaptchaLoaded
}: TCaptchaIframeProps) => {
  const {
    showChallengeRecaptcha
  } = useCaptcha();

  return (
    <div
      className={classNames({
        [styles.contentWrapper]: showChallengeRecaptcha,
        [styles.invisibleCaptcha]: !showChallengeRecaptcha
      })}
    >
      <div className="modal-content">
        <h2><strong>Verify you're human</strong></h2>
        <iframe
          ref={iframeRef}
          onLoad={() => setIsIframeCaptchaLoaded(true)}
          src={iframeURL}
          title="Recaptcha for Eagle"
          className={styles.recaptchaIframe}
        />
      </div>
    </div>
  );
};

export default CaptchaIframe;
